import { Injectable } from '@angular/core';
import { SERVER_API_URL } from 'app/app.constants';
import { HttpClient } from '@angular/common/http';
import { Regulator } from 'app/shared/model/project.model';
import { Observable } from 'rxjs';

import { IDocumentContext } from 'app/shared/model/document.model';

@Injectable({ providedIn: 'root' })
export class ContextService {
  public currentProjectId: number;
  public hasTaxonomyImported = false;
  public hasRegulator = false;
  public regulator = Regulator.NONE;
  public currentDocumentContext: IDocumentContext;
  public canSyncLore = false;
  public authorManagement = false;
  public allowExportHDPdf = false;
  public twoLettersLanguage = '';

  constructor(private http: HttpClient) {}

  public getContext(id: number): Observable<any> {
    return this.http.get<any>(`${SERVER_API_URL}api/documents/${id}/context`);
  }

  public initFromDocumentContext(documentContext: IDocumentContext): void {
    this.currentDocumentContext = documentContext;
    this.currentProjectId = documentContext.projectId;
    this.hasTaxonomyImported = documentContext.hasTaxonomyImported;
    this.hasRegulator = documentContext.hasRegulator;
    this.regulator = documentContext.regulator;
    this.canSyncLore = documentContext.canSyncLore;
    this.authorManagement = documentContext.authorManagement;
    this.allowExportHDPdf = documentContext.allowExportHDPdf;
    this.twoLettersLanguage = documentContext.language.code?.substring(0, 2).toLowerCase() ?? '';
  }
}
