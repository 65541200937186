<div class="dynamic-data-plugin-container">
  <div [ngSwitch]="panel">
    <div *ngSwitchCase="'table'">
      <jhi-dynamic-data-table-picker [dataTypeAccess]="dataTypeAccess" (selected)="selectFormatPanel($event)">
      </jhi-dynamic-data-table-picker>
    </div>
    <div *ngSwitchCase="'partialTable'">
      <jhi-dynamic-data-picker [isSelectionMultiple]="true" (selected)="selectFormatPanel($event)"></jhi-dynamic-data-picker>
    </div>
    <div *ngSwitchCase="'xbrl'">
      <jhi-xbrl-fact-picker (selected)="selectFormatPanel($event)"></jhi-xbrl-fact-picker>
    </div>
    <div *ngSwitchCase="'dynamicData'">
      <jhi-dynamic-data-picker (selected)="selectFormatPanel($event)"></jhi-dynamic-data-picker>
    </div>
    <div *ngSwitchCase="'formatingData'">
      <jhi-dynamic-data-formater [editor]="editor" (resetPanel)="showPanel(null)" [dynamicData]="selectedData"></jhi-dynamic-data-formater>
    </div>
    <div *ngSwitchDefault class="selectors">
      <button class="btn btn-light custom-btn" (click)="showPanel('table')">
        {{ 'htmlEditor.plugins.dynamicData.picking.fullTable' | translate }}
      </button>
      <button class="btn btn-light custom-btn" (click)="showPanel('partialTable')">
        {{ 'htmlEditor.plugins.dynamicData.picking.partialTable' | translate }}
      </button>
      <button class="btn btn-light custom-btn" (click)="showPanel('dynamicData')">
        {{ 'htmlEditor.plugins.dynamicData.picking.dynamicData' | translate }}
      </button>
      <button class="btn btn-light custom-btn" (click)="showPanel('xbrl')" [disabled]="!hasTaxonomy" *ngIf="isXbrlProject">
        {{ 'htmlEditor.plugins.dynamicData.picking.xbrlData' | translate }}
      </button>
    </div>
  </div>
</div>
