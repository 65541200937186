import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountService } from 'app/core/auth/account.service';
import { ContextService } from 'app/core/service/context.service';
import { DocumentaryUnitService } from 'app/core/service/documentary-unit.service';
import { IDocumentaryUnit } from 'app/shared/model/documentary-unit.model';
import { PluginComponent } from '../../plugin.component';
import { PluginPanelService } from '../../plugin-panel.service';
import { DocumentService } from 'app/core/service/document.service';

import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_TOGGLEPANEL } from 'app/pubsub.topics';
import { PLUGIN_PANEL_COMPONENT_KEYS } from '../../plugin-panel-component-keys';
import { Authority } from 'app/shared/enum/authority.enum';
import { ExportType } from 'app/shared/model/download-manager.model';
import { ConfigurationService } from 'app/core/service/configuration.service';

@Component({
  selector: 'jhi-configuration-export-pdf',
  templateUrl: './configuration-export-pdf.component.html',
  styleUrls: ['./configuration-export-pdf.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigurationExportPdfComponent extends PluginComponent {
  public readonly Authority = Authority;
  public readonly ExportType = ExportType;

  public accessibleToggle = false;
  public disableAccessibleToggle = false;

  public hasCommentsToggle = false;
  public isFilterOnActiveCommentsToggle = false;
  public hasSuggestionsToggle = false;
  public hasPriorityToggle = false;
  public isPdfExportDisablePriority = false;

  public exportForm: UntypedFormGroup;
  public sections: IDocumentaryUnit[];

  public hasAuthorityPdfHD;

  constructor(
    public pluginPanelService: PluginPanelService,
    private accountService: AccountService,
    private contextService: ContextService,
    private documentService: DocumentService,
    private configurationService: ConfigurationService,
    private documentaryUnitService: DocumentaryUnitService
  ) {
    super(pluginPanelService);
  }

  ngOnInit(): void {
    this.loadSections(true);

    // US client are free to use HD export with "allow-pdf" set to true in application.yml report in context service: allowExportHDPdf
    this.hasAuthorityPdfHD =
      this.accountService.hasAnyAuthority([Authority.EXPORT_PDF_HD]) ||
      (this.contextService.allowExportHDPdf && this.accountService.hasAnyAuthority([Authority.CLI_COR]));

    this.configurationService.getConfiguration().subscribe(data => {
      this.isPdfExportDisablePriority = data.pdfExportDisablePriority;
    });

    this.exportForm = new UntypedFormGroup({
      quality: new UntypedFormControl('LD', { validators: [Validators.required] }),
      typeExportSelected: new UntypedFormControl(ExportType.CHAPTER),
      sectionsSelected: new UntypedFormControl({ value: null, disabled: true }),
    });

    this.exportForm.controls.quality.valueChanges.subscribe(value => {
      if (value !== 'LD') {
        this.accessibleToggle = false;
        this.disableAccessibleToggle = true;
      } else {
        this.disableAccessibleToggle = false;
      }
    });

    this.exportForm.controls.typeExportSelected.valueChanges.subscribe(value => {
      if (value !== ExportType.CHAPTERS) {
        this.exportForm.controls.sectionsSelected.disable({ emitEvent: false });
        this.exportForm.controls.sectionsSelected.clearValidators();
      } else {
        this.exportForm.controls.sectionsSelected.enable({ emitEvent: false });
        this.exportForm.controls.sectionsSelected.setValidators([Validators.required]);
      }
      this.exportForm.controls.sectionsSelected.updateValueAndValidity();
    });
  }

  goBack(): void {
    pubsub.fire(EDITOR_PLUGIN_TOGGLEPANEL, {
      component: PLUGIN_PANEL_COMPONENT_KEYS.DOCUMENT_EXPORT,
      title: 'htmlEditor.plugins.documentExport.title',
    });
  }

  exportDocumentPDF(): void {
    this.documentService.downloadPdfExport(
      this.exportForm?.get('quality')?.value,
      this.exportForm?.get('typeExportSelected')?.value,
      this.hasCommentsToggle || this.isFilterOnActiveCommentsToggle,
      this.isFilterOnActiveCommentsToggle,
      this.hasSuggestionsToggle,
      this.accessibleToggle,
      this.hasPriorityToggle,
      this.exportForm?.get('sectionsSelected')?.value
    );
    this.pluginPanelService.closePanel();
  }

  onChangeToggleComment() {
    if (this.isFilterOnActiveCommentsToggle) {
      this.isFilterOnActiveCommentsToggle = false;
    }
  }

  onChangeToggleFilter() {
    if (this.hasCommentsToggle) {
      this.hasCommentsToggle = false;
    }
  }

  private loadSections(showSpinner = false): void {
    this.documentaryUnitService.getSections(showSpinner).subscribe(sections => (this.sections = sections));
  }
}
