import { enableProdMode } from '@angular/core';
import { registerLicense } from '@syncfusion/ej2-base';
import {
  ALLOW_SENTRY,
  DEBUG_INFO_ENABLED,
  VERSION,
  SENTRY_DNS,
  SENTRY_TRACE_SAMPLE_RATE,
  SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
} from 'app/app.constants';

import { init, BrowserTracing, instrumentAngularRouting } from '@sentry/angular-ivy';
import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';

export function ProdConfig(): void {
  // disable debug data on prod profile to improve performance
  if (!DEBUG_INFO_ENABLED) {
    enableProdMode();
  }

  registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9cd3VVQmFZV0V+X0Y=');

  if (ALLOW_SENTRY) {
    init({
      environment: window.location.hostname,
      release: VERSION,
      dsn: SENTRY_DNS,
      integrations: [
        new BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [/.*/],
          routingInstrumentation: instrumentAngularRouting,
        }),
        new Sentry.Replay(),
        new CaptureConsole({ levels: ['error'] }),
      ],
      // Performance Monitoring
      tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE, // Capture 50% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: SENTRY_REPLAYS_SESSION_SAMPLE_RATE, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
}
