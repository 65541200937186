import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'app/core/auth/account.service';
import { ArevioService } from 'app/core/service/arevio.service';
import { DynamicDataService } from 'app/html-editor/plugins/dynamic-data/dynamic-data.service';
import { PluginPanelService } from 'app/html-editor/plugins/plugin-panel.service';
import { PluginComponent } from 'app/html-editor/plugins/plugin.component';
import { PluginsCommand } from 'app/html-editor/plugins/plugins-commands';
import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_EXECUTE, EDITOR_PLUGIN_GOTODYNAMICDATA, FOOTNOTE_SCROLL_TO } from 'app/pubsub.topics';
import { Authority } from 'app/shared/enum/authority.enum';
import { Direction } from 'app/shared/enum/direction.enum';
import { DynamicDataFilter } from 'app/shared/enum/dynamic-data.enum';

import { XBRLType } from 'app/shared/enum/xbrl-type.enum';
import { VALUE_TYPE } from 'app/shared/enum/xslx.enum';
import { IFact, IFactUtils } from 'app/shared/model/fact.model';
import { IDataAndFormat, IFormatAttributes } from 'app/shared/model/formatted.model';
import { IDynamicData } from 'app/shared/model/rcsf.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'jhi-dynamic-data-viewer-plugin',
  templateUrl: './dynamic-data-viewer-plugin.component.html',
  styleUrls: ['./dynamic-data-viewer-plugin.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicDataViewerPluginComponent extends PluginComponent implements OnDestroy {
  public selectedFact: IFact | null;
  public selectedFactIdList: string[];
  public selectedDynamicData: IDynamicData | null;
  public selectedData: IFact | IDynamicData | null;
  public displayAttachXbrlFact = false;
  public isEditable = false;
  public factToAttach: IFact;
  public filteredType = XBRLType.STRING;
  public isFactToAttachValid = false;
  public isPicking = false;
  public isUpdate = false;
  public warningMessage: string = '';
  public formatAttributes: IFormatAttributes;
  private subscriptions = new Subscription();
  private _factSubscription: Subscription;
  private _dynamicDataSubscription: Subscription;

  constructor(
    pluginPanelService: PluginPanelService,
    private dynamicDataService: DynamicDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private accountService: AccountService,
    private arevioService: ArevioService,
    private translate: TranslateService
  ) {
    super(pluginPanelService);
    this._factSubscription = this.dynamicDataService
      .getSelectedFactObservable()
      .subscribe(({ data, format, factIdList }: IDataAndFormat) => {
        this.selectedDynamicData = null;
        this.selectedFact = data as IFact;
        this.formatAttributes = format;
        this.selectedFactIdList = factIdList ?? (this.selectedFact.factXbrlId ? [this.selectedFact.factXbrlId] : []);

        this.checkIsEditableForFacts();
        if (this.selectedData) {
          this.goToEditFormat();
        }
        this.changeDetectorRef.markForCheck();
      });

    this._dynamicDataSubscription = this.dynamicDataService.getSelectedDataObservable().subscribe(({ data, format }: IDataAndFormat) => {
      this.selectedFact = null;
      this.selectedDynamicData = data as IDynamicData;
      this.formatAttributes = format;
      this.checkIsEditableForDynamicData();
      if (this.selectedData && this.isEditable) {
        this.goToEditFormat();
      } else {
        this.selectedData = null;
      }

      this.changeDetectorRef.markForCheck();
    });
  }

  /**
   * fact selected in selection fact
   */
  public updateFact(fact: IFact): void {
    this.factToAttach = fact;
  }

  /**
   * action when fact selection step change
   */
  public updateStep(): void {
    this.isFactToAttachValid = false;
    this.isPicking = false;
    this.warningMessage = '';
    this.isUpdate = false;

    if (!this.factToAttach.context?.period?.id) {
      this.changeDetectorRef.markForCheck();
      return;
    }

    this.factToAttach.factValue = this.selectedFact?.factValue;
    this.factToAttach.factXbrlId = this.arevioService.getFactXbrlId(this.factToAttach);

    if (this.selectedFactIdList.includes(this.factToAttach.factXbrlId)) {
      this.warningMessage = this.translate.instant('htmlEditor.plugins.dynamicData.viewer.warning.sameFact');
      this.changeDetectorRef.markForCheck();
      return;
    }

    this.arevioService.getFactById(this.factToAttach.factXbrlId).subscribe(fact => {
      this.isFactToAttachValid = true;
      if (fact?.id && this.factToAttach.factValue === fact.factValue) {
        this.isPicking = true;
      } else if (fact?.id) {
        this.factToAttach.id = fact.id;
        if (IFactUtils.isStringMultiZone(fact)) {
          // multi zone fact not allowed
          this.warningMessage = this.translate.instant('htmlEditor.plugins.dynamicData.viewer.warning.errorMultiPart');
          this.isFactToAttachValid = false;
        } else {
          this.isUpdate = true;
          this.warningMessage = this.translate.instant('htmlEditor.plugins.dynamicData.viewer.warning.valueChange', {
            value: fact.factValue,
            newValue: this.factToAttach.factValue,
          });
        }
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  public scrollToAnnotation(footnoteId: number): void {
    this.closePanel();
    pubsub.fire(FOOTNOTE_SCROLL_TO, { footnoteId }, this.getEditorTopicContext());
  }

  private checkIsEditableForDynamicData(): void {
    switch (this.selectedDynamicData?.cell?.valueType) {
      case VALUE_TYPE.NUMERIC:
      case VALUE_TYPE.DATE:
        this.isEditable = !this.isReadOnlyMode && this.accountService.hasAnyAuthority(Authority.TAG_DYNAMIC_DATA);
        break;
      default:
        this.isEditable = false;
        break;
    }
  }

  private checkIsEditableForFacts(): void {
    switch (this.selectedFact?.concept?.type) {
      case XBRLType.MONETARY:
      case XBRLType.PER_SHARE:
      case XBRLType.PER_SHARE_2021:
      case XBRLType.SHARES:
      case XBRLType.DATE:
      case XBRLType.DECIMAL:
        this.isEditable = !this.isReadOnlyMode && this.accountService.hasAnyAuthority(Authority.TAG_DYNAMIC_DATA);
        break;
      default:
        this.isEditable = false;
        break;
    }
  }

  private _initFact(): void {
    this.factToAttach = {
      context: {
        dimensions: [],
        period: null,
        entityScheme: '',
        entityIdentifier: null,
      },
    };
  }

  ngOnDestroy(): void {
    this._factSubscription.unsubscribe();
    this._dynamicDataSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  /**
   * Move selection to next dynamic data (XBRL or DD) in the Editor
   * direction can be ASC : next data in the document or DESC, previous data in the document
   */
  goToDynamicData({ dir, filter }: { dir: Direction; filter: DynamicDataFilter }): void {
    pubsub.fire(EDITOR_PLUGIN_GOTODYNAMICDATA, { direction: dir, filter }, this.getEditorTopicContext());
  }

  goToEditFormat(): void {
    if (this.selectedFact) {
      this.selectedData = this.selectedFact;
      this.selectedFact = null;
    } else if (this.selectedDynamicData) {
      this.selectedData = this.selectedDynamicData;
      this.selectedDynamicData = null;
    } else {
      this.selectedFact = null;
      this.selectedDynamicData = null;
    }

    this.changeDetectorRef.markForCheck();
  }

  /**
   * init and show fact selection block
   */
  public goToAttachXbrlFact(): void {
    this._initFact();
    this.warningMessage = '';
    this.isUpdate = false;
    this.displayAttachXbrlFact = true;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * detach a fact in list id, can't remove the last one
   */
  public detachXbrlFact(factId: string): void {
    if (!factId || this.selectedFactIdList.length <= 1) {
      return;
    }
    this.selectedFactIdList = this.selectedFactIdList.filter(id => factId !== id);
    this.arevioService.getFactById(this.selectedFactIdList[0]).subscribe(fact => {
      this.selectedFact = fact;
      this._updateFactIdInEditor();
    });
  }

  /**
   * cancel attach action
   */
  public cancelAttachXbrlFact(): void {
    this.displayAttachXbrlFact = false;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * attach a fact
   * for picking action, just link the fact
   * for an existing fact with a different value, update fact value and link the fact
   * for a new fact, create fact and link it
   */
  public attachXbrlFact(): void {
    if (this.isPicking) {
      this._linkFact(this.factToAttach.factXbrlId);
    } else if (this.isUpdate) {
      this._updateFact();
    } else {
      this._createFact();
    }
  }

  private _updateFactIdInEditor(): void {
    pubsub.fire(
      EDITOR_PLUGIN_EXECUTE,
      {
        command: PluginsCommand.INSERT_XBRL,
        params: { factIdList: this.selectedFactIdList },
      },
      this.getEditorTopicContext()
    );
  }

  /**
   * link a fact
   */
  private _linkFact(factXbrlId: string | undefined): void {
    if (!factXbrlId) {
      return;
    }

    this.selectedFactIdList.push(factXbrlId);
    this.displayAttachXbrlFact = false;
    this._updateFactIdInEditor();
    this.changeDetectorRef.markForCheck();
  }

  /**
   * create a new fact and link it
   */
  private _createFact(): void {
    if (!this.factToAttach.concept || !this.factToAttach.context?.period) {
      return;
    }
    const dimensions = this.factToAttach.context?.dimensions
      .filter(dim => dim?.dimAxis?.qname || dim?.dimMember?.qname) // purge null dim
      .map(dim => ({
        axisQname: dim.dimAxis.qname,
        memberQnames: dim.dimMember.qname,
      }));

    const { qname, type } = this.factToAttach.concept;
    const { oimUnit } = this.factToAttach;
    const { startDate, endDate, periodType } = this.factToAttach.context.period;
    this.arevioService
      .tagNewFact(
        {
          qname,
          dimensions,
          factValue: this.factToAttach.factValue,
          oimUnit,
          startDate,
          endDate,
          periodType,
        },
        type
      )
      .subscribe((fact: IFact) => {
        this._linkFact(fact.factXbrlId);
      });
  }

  /**
   * update fact value and link it
   */
  private _updateFact(): void {
    if (!this.factToAttach.id || !this.factToAttach.factValue) {
      return;
    }
    this.arevioService
      .setFactValue({
        factId: this.factToAttach.id,
        factValue: this.factToAttach.factValue,
      })
      .subscribe(() => {
        this._linkFact(this.factToAttach.factXbrlId);
      });
  }
}
