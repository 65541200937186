<div class="export-configuration-container">
  <div class="back-container">
    <button mat-icon-button class="header-close" (click)="goBack()">
      <mat-icon svgIcon="arrow-back" color="primary"></mat-icon>
    </button>
    <span jhiTranslate="htmlEditor.plugins.configurationExport.goBack"></span>
  </div>
  <hr />
  <div class="configuration-content">
    <div class="type-reminder">
      <mat-icon class="export-svg" svgIcon="export-pdf"></mat-icon>
      <span jhiTranslate="htmlEditor.plugins.documentExport.pdfExport"></span>
    </div>
    <form class="export-filter-form-container" role="form" [formGroup]="exportForm">
      <mat-form-field appearance="fill">
        <mat-label jhiTranslate="htmlEditor.plugins.configurationExport.PDF.quality"></mat-label>
        <mat-select formControlName="quality" required>
          <mat-option value="LD" selected *jhiHasAnyAuthority="[Authority.EXPORT_PDF_LD]">{{
            'htmlEditor.plugins.configurationExport.LD' | translate
          }}</mat-option>
          <mat-option value="HD" *ngIf="hasAuthorityPdfHD">{{ 'htmlEditor.plugins.configurationExport.HD' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <label
        id="export-content-label"
        class="configuration-content-label"
        jhiTranslate="htmlEditor.plugins.configurationExport.export"
      ></label>
      <mat-radio-group aria-labelledby="export-content-label" formControlName="typeExportSelected" color="primary">
        <mat-radio-button [value]="ExportType.CHAPTER">
          {{ 'htmlEditor.plugins.configurationExport.chapter' | translate }}
        </mat-radio-button>
        <mat-radio-button class="export-chapters-button" [value]="ExportType.CHAPTERS">
          {{ 'htmlEditor.plugins.configurationExport.dus' | translate }}
        </mat-radio-button>
        <jhi-select-d-us-field [sections]="sections" formControlName="sectionsSelected"></jhi-select-d-us-field>
        <mat-radio-button [value]="ExportType.DOCUMENT">
          {{ 'htmlEditor.plugins.configurationExport.document' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </form>
    <label
      id="export-options-label"
      class="configuration-content-label"
      jhiTranslate="htmlEditor.plugins.configurationExport.options.label"
    ></label>
    <div aria-labelledby="export-options-label" class="configuration-options-toggle">
      <mat-slide-toggle class="toggle" color="primary" (change)="onChangeToggleComment()" [(ngModel)]="hasCommentsToggle">
        {{ 'htmlEditor.plugins.configurationExport.options.comments' | translate }}
      </mat-slide-toggle>
      <mat-slide-toggle class="toggle" color="primary" (change)="onChangeToggleFilter()" [(ngModel)]="isFilterOnActiveCommentsToggle">
        {{ 'htmlEditor.plugins.configurationExport.options.filterOnActiveComments' | translate }}
      </mat-slide-toggle>
      <mat-slide-toggle class="toggle" color="primary" [(ngModel)]="hasSuggestionsToggle">
        {{ 'htmlEditor.plugins.configurationExport.options.suggestions' | translate }}
      </mat-slide-toggle>
      <ng-container *jhiHasAnyAuthority="[Authority.PRIORITY_EXPORT]">
        <mat-slide-toggle class="toggle" color="primary" [(ngModel)]="hasPriorityToggle" *ngIf="!isPdfExportDisablePriority">
          {{ 'htmlEditor.plugins.configurationExport.options.priority' | translate }}
        </mat-slide-toggle>
      </ng-container>
    </div>

    <div class="button-container">
      <button
        [disabled]="!exportForm.valid"
        type="button"
        mat-flat-button
        mat-button
        color="primary"
        (click)="exportDocumentPDF()"
        jhiTranslate="htmlEditor.plugins.configurationExport.button"
      ></button>
      <span *ngIf="!exportForm.valid" jhiTranslate="htmlEditor.plugins.configurationExport.PDF.disabled"></span>
    </div>
  </div>
</div>
