import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StateStorageService } from 'app/core/auth/state-storage.service';
import { DialogService } from 'app/core/service/dialog.service';
import { LoaderService } from 'app/core/service/loader.service';
import { ArevioCodeError } from 'app/shared/enum/arevio-code-error.enum';
import { HttpErrorCustomResponse } from 'app/shared/model/http-error-custom-response.model';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Injectable()
export class PGDPHttpInterceptor implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private stateStorageService: StateStorageService,
    private router: Router,
    private dialogService: DialogService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.params.get('spinner') && !request.urlWithParams.includes('spinner=none')) {
      this.loaderService.show();
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorCustomResponse) => {
        if (request.params.get('skipError') === 'true') {
          // Allow to be caught in the service without opoen error dialog
          return throwError(error);
        }
        const isOnlyTemplate = error.error?.['onlyTemplate'] ?? 'false';

        switch (error.status) {
          case 400:
            // TODO review code below : made it to make date errors work
            this.dialogService.openErrorDialog(error.error?.errorKey !== undefined ? error.error.errorKey : error.error);
            break;
          case 405:
            this.dialogService.openErrorDialog(ArevioCodeError.NOT_ALLOWED);
            break;
          case 418:
            this.router.navigateByUrl(`/importProjectDetail/${error.error['projectId']}?isOnlyTemplate=${isOnlyTemplate}`);
            break;
          case 500:
            this.dialogService.openErrorDialog(error.error?.title !== undefined ? error.error?.title : error.error);
            break;
          case 406:
            // Used when the error object is needed in component
            // Exemple : for CSS import, font deletion, taxonomy import.
            return throwError(error);
          case 409:
          case 412:
            // Used in xliff to open a mapping dialog
            return throwError(error);
        }
        return throwError(`Error Code: ${error.status}\nMessage: ${error.message}\nDetails: ${JSON.stringify(error.error)}`);
      }),
      finalize(() => {
        if (!request.params.get('spinner')) {
          this.loaderService.hide();
        }
      })
    );
  }
}
