import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SERVER_API_URL } from 'app/app.constants';

import { ContextService } from './context.service';
import { DownloadManagerService } from './download-manager.service';
import { DocumentaryUnitService } from './documentary-unit.service';
import { IDocumentProperties } from 'app/shared/model/document-properties.model';
import { LogEntryFactValidationDTO } from 'app/shared/model/fact.model';
import { ExportType } from 'app/shared/model/download-manager.model';

@Injectable({ providedIn: 'root' })
export class DocumentService {
  public resourceUrl = `${SERVER_API_URL}api/documents`;

  constructor(
    private http: HttpClient,
    private contextService: ContextService,
    private downloadManagerService: DownloadManagerService,
    private documentaryUnitService: DocumentaryUnitService
  ) {}

  public downloadXHTMLExport(): void {
    this.http
      .get(`${SERVER_API_URL}/api/documents/${this.contextService.currentDocumentContext.id}/export/xhtml`, {
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe(() => {
        this.downloadManagerService.forceCall();
      });
  }

  public downloadRegulatorArchive(validate = false): void {
    const params = new HttpParams().set('validate', String(validate));
    this.http
      .get(`${SERVER_API_URL}/api/documents/${this.contextService.currentDocumentContext.id}/export/regulator-archive`, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe(() => {
        this.downloadManagerService.forceCall();
      });
  }

  public downloadCleanedHTMLExport(): void {
    this.http
      .get(`${SERVER_API_URL}/api/documents/${this.contextService.currentDocumentContext.id}/export/cleanedHtml`, {
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe(() => {
        this.downloadManagerService.forceCall();
      });
  }

  public downloadLoreExport(syncLore: boolean): void {
    let params = new HttpParams();
    params = params.append('sync', String(syncLore));

    this.http
      .get(`${SERVER_API_URL}/api/documents/${this.contextService.currentDocumentContext.id}/export/cleanedHtml/lore`, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe(() => {
        this.downloadManagerService.forceCall();
      });
  }

  public downloadUDCleanedHTMLExport(damId: number, noMainSpinner = false): Observable<HttpResponse<string>> {
    let params = new HttpParams();
    if (noMainSpinner) {
      params = params.set('spinner', 'none');
    }
    return this.http.get(
      `${SERVER_API_URL}/api/documents/${this.contextService.currentDocumentContext.id}/export/ud/${damId}/cleanedHtml`,
      {
        observe: 'response',
        responseType: 'text',
        params,
      }
    );
  }

  public getPreviewContent(damId: number, noMainSpinner = false): Observable<HttpResponse<string>> {
    let params = new HttpParams();
    if (noMainSpinner) {
      params = params.set('spinner', 'none');
    }
    return this.http.get(
      `${SERVER_API_URL}/api/poc/documents/${this.contextService.currentDocumentContext.id}/preview/preview?chapterOffset=1&quality=LD&udId=${damId}&isPreview=true&singlePage=true&fullChapter=false`,
      {
        observe: 'response',
        responseType: 'text',
        params,
      }
    );
  }

  public downloadPdfExport(
    quality: string,
    exportType: ExportType,
    includeComments: boolean,
    filterOnActiveComments: boolean,
    includeSuggestions: boolean,
    accessible: boolean,
    priority: boolean,
    documentaryUnitsId: number[] = []
  ): void {
    let params = new HttpParams()
      .set('includeComments', String(includeComments))
      .set('filterOnActiveComments', String(filterOnActiveComments))
      .set('includeSuggestions', String(includeSuggestions))
      .set('accessible', String(accessible))
      .set('priority', String(priority));
    let url = `${SERVER_API_URL}/api/documents/${this.contextService.currentDocumentContext.id}/export/pdf/${quality}`;
    if (exportType === ExportType.CHAPTER) {
      url += `/chapter/${this.documentaryUnitService.currentSelectedDocumentaryUnit?.id}`;
    } else if (exportType === ExportType.CHAPTERS) {
      url += `/documentaryUnits`;
      params = params.append('documentaryUnitsId', documentaryUnitsId.join(','));
    }

    this.http
      .get(url, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe(() => {
        this.downloadManagerService.forceCall();
      });
  }

  public downloadXLIFFExport(language: { sourceLanguage: string; targetLanguage: string }, type: ExportType, marks: boolean): void {
    const documentID = this.contextService.currentDocumentContext.id;
    const docUnitID = this.documentaryUnitService.currentSelectedDocumentaryUnit?.id;
    const url = `${SERVER_API_URL}/api/documents/${documentID}/xliff/documentary-unit/${docUnitID}/export/${language.sourceLanguage}/${language.targetLanguage}/${type}`;
    let params = new HttpParams();
    params = params.append('includeMarks', marks.toString());
    this.http
      .get(url, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe(() => {
        this.downloadManagerService.forceCall();
      });
  }

  public downloadWordExport(sections: number[] | null): void {
    let params = new HttpParams();
    if (sections != null) {
      const documentaryUnitsId: string[] = [];
      documentaryUnitsId.push(
        ...sections.map(section => {
          return section.toString();
        })
      );
      params = params.append('documentaryUnitsId', documentaryUnitsId.join(','));
    }
    this.http
      .get(`${SERVER_API_URL}/api/documents/${this.contextService.currentDocumentContext.id}/export/doc`, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe(() => {
        this.downloadManagerService.forceCall();
      });
  }

  public downloadXHTMLExportWithViewer(): void {
    this.http
      .get(`${SERVER_API_URL}/api/documents/${this.contextService.currentDocumentContext.id}/export/viewer`, {
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe(() => {
        this.downloadManagerService.forceCall();
      });
  }

  public downloadUDPaginationPreview(damId: number, noMainSpinner = false): Observable<HttpResponse<string>> {
    let params: HttpParams = new HttpParams();
    if (noMainSpinner) {
      params = params.set('spinner', 'none');
    }
    return this.http.get(`${SERVER_API_URL}/api/documents/${this.contextService.currentDocumentContext.id}/preview/ud/${damId}`, {
      observe: 'response',
      responseType: 'text',
      params,
    });
  }

  public getDocumentProperties(): Observable<IDocumentProperties> {
    const params = new HttpParams().set('spinner', 'none');
    return this.http.get<IDocumentProperties>(
      `${SERVER_API_URL}/api/documents/${this.contextService.currentDocumentContext.id}/properties`,
      { params }
    );
  }

  public verifyFactLink(): Observable<LogEntryFactValidationDTO[]> {
    // We should return that once the back-end will be finished
    return this.http.get<LogEntryFactValidationDTO[]>(
      `${SERVER_API_URL}/api/xbrl-footnote/project/${this.contextService.currentProjectId}/document/${this.contextService.currentDocumentContext.id}/check`
    );
  }

  public updateDocumentPageNumberOffset(pageNumberOffset: number): Observable<IDocumentProperties> {
    const params = new HttpParams().set('pageNumberOffset', `${pageNumberOffset}`);
    return this.http.put<IDocumentProperties>(
      `${SERVER_API_URL}/api/documents/${this.contextService.currentDocumentContext.id}/properties`,
      null,
      { params }
    );
  }

  public updateDocumentHideWarningZones(hideWarningZones: boolean): Observable<IDocumentProperties> {
    const params = new HttpParams().set('hideWarningZones', `${hideWarningZones}`);
    return this.http.put<IDocumentProperties>(
      `${SERVER_API_URL}/api/documents/${this.contextService.currentDocumentContext.id}/properties`,
      null,
      { params }
    );
  }

  // Used to hide Labarador contributor names
  public getClientDomains(): Observable<string[]> {
    return this.http.get<string[]>(`${SERVER_API_URL}/api/documents/${this.contextService.currentDocumentContext.id}/client-domains`);
  }
}
